import apiRequest from '@helpers/apiRequest'


const handleFileDownload = (response) => {
    const fileName = response.data.fileName;
    const linkSource = response.data.file;
    const tempLink = document.createElement('a');
    tempLink.href = linkSource;
    tempLink.download = fileName;
    tempLink.click();
};

const getExcelParticipants = (data) => {
    apiRequest('POST', 'File/Participants', data).then((response) => {
        handleFileDownload(response);
    });
};

const getPdfEvaluator = (data) => {
    return apiRequest('POST', "File/GenerateEvaluatorReport", data.idEvaluatorParticipant)
        .then((response) => {
            handleFileDownload(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const sendPdfEvaluator = (data) => {
    return apiRequest('POST', "File/SendEvaluatorReport", data.idEvaluatorParticipant);
};

const getWordFeedback = (data) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', 'File/Feedback', data)
            .then((response) => {
                handleFileDownload(response);
                resolve(); // Resuelve la promesa después de iniciar la descarga
            })
            .catch((error) => {
                reject(error); // Rechaza la promesa en caso de error
            });
    });
};

const getDiploma = (idParticipant, idCycle, print) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', `File/Diploma/${idParticipant}/${idCycle}/${print}`, idParticipant, idCycle, print)
            .then((response) => {
                handleFileDownload(response);
                resolve(); // Resuelve la promesa después de iniciar la descarga
            })
            .catch((error) => {
                reject(error); // Rechaza la promesa en caso de error
            });
    });
};



// FILES

const getFilesAttachList = data => {
  return apiRequest('POST', 'File/List', data)
}
const getFilesAttachDetail = idFileAttach => {
  return apiRequest('GET', `File/Details/${idFileAttach}`)
}

const updateFilesAttach = data => {
  const { idFileAttach } = data
  return apiRequest('PUT', `File/Update/${idFileAttach}`, data)
}

const newFilesAttach = data => {
  return apiRequest('POST', 'File/New', data)
}

const deleteFilesAttach = data => {
  const idFileAttach = data.idFileAttach
  return apiRequest('DELETE', `File/Delete/${idFileAttach}`)
}

const saveParticipantFile = async (data) => {
  return await apiRequest('POST', 'Cycles/SaveParticipantFile', data);
};

// ✅ Obtener todos los participantes con archivo
const getAllParticipantsFiles = () => {
  return apiRequest('GET', 'Cycles/GetAllParticipantsFiles');
};

// ✅ Obtener el archivo de un único participante
const getParticipantFile = async (idParticipant) => {
  try {
    const response = await apiRequest('GET', `Cycles/GetParticipantFile?idParticipant=${idParticipant}`);
    return response;
  } catch (error) {
    // Solo tratamos como "normal" el caso 404
    if (error?.response?.status === 404) {
      console.log(`📂 Participante ${idParticipant} no tiene QTRFile (esto es normal)`);
      return { data: null };
    }

    // Cualquier otro error lo mostramos
    console.error(`❌ Error al obtener QTRFile para participante ${idParticipant}:`, error);
    return { error: true };
  }
};

// ✅ Obtener todos los participantes con archivo que están en un grupo
const getParticipantsFilesByCycle = (idCycle) => {
  return apiRequest('GET', `Cycles/GetParticipantsFilesByCycle?idCycle=${idCycle}`);
};

// ✅ Eliminar archivo
const deleteParticipantFile = (idParticipant) => {
  return apiRequest('DELETE', `Cycles/DeleteParticipantFile?idParticipant=${idParticipant}`);
};

const serviceFiles = {
  getExcelParticipants,
  getWordFeedback,
  getDiploma,
  getFilesAttachList,
  getFilesAttachDetail,
  updateFilesAttach,
  newFilesAttach,
  deleteFilesAttach,
  getPdfEvaluator,
  sendPdfEvaluator,
  saveParticipantFile,
  getAllParticipantsFiles,
  getParticipantFile,
  deleteParticipantFile,
  getParticipantsFilesByCycle
}

export default serviceFiles
