import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { Box, Grid, TextField, Typography, Chip, Button } from '@mui/material'
import CheckBox from '@components/CheckBox'
import ReactDevicePreview from 'react-device-preview'
import serviceMobileSheets from '@services/serviceMobileSheets'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LocaleTabsDorOWToolbox from '@components/LocaleTabsDorOWToolbox'
import Card from '@components/Card'
import { useForm } from '@hooks/useForm'
import DraggableElements from './components/DraggableElements'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import HeaderTitle from '@components/HeaderTitle'
import FormActions from '@components/FormActions'
import Dialog from '@components/Dialog'
import ColorPicker from '@components/ColorPicker'
import Loading from '@components/Loading'
import ContentMobileForOWToolbox from '@components/ContentMobileForOWToolbox'
import { snackActions } from '@helpers/snackbarUtils'
import { locale } from 'dayjs'
import { ListContext } from '@context/ListContext';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const OwToolboxDetail = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [typesList, setTypesList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddElement, setShowAddElement] = useState(false)
  const [localRenderTrigger, setLocalRenderTrigger] = useState(0);

  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useCommonStyles()

  const {
    state: { data: MOBILESHEET_DATA, type: TYPE }
  } = useLocation()

  const HEADER_TITLE =
    TYPE === 'NEW' ? t('OWTOOLS.NEW_TITLE') : t('OWTOOLS.UPDATE_TITLE')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('OWTOOLS.NEW_SUBTITLE') : t('OWTOOLS.UPDATE_SUBTITLE')

  const initialFormData = {
    mobileSheetLogo: false,
    mobileSheetItems: [],
    mobileSheetColor: '#F1F1F1',
    locales: []
  }

  const selectedLanguage = sessionStorage.getItem('idLanguage');
  
  const { formData, handleChange, setFormData } = useForm(    
    MOBILESHEET_DATA
      ? {
          ...MOBILESHEET_DATA,
          mobileSheetItems: MOBILESHEET_DATA.mobileSheetItems
            .sort((a, b) => a.mobileSheetItemOrder - b.mobileSheetItemOrder)
            .map((e, i) => ({
              ...e,
              idDrag: e.idDrag || `Item-${e.idMobileSheetItem || i}-${Date.now()}`
            })),
          mobileSheetLogo: MOBILESHEET_DATA.mobileSheetLogo !== undefined ? MOBILESHEET_DATA.mobileSheetLogo : false
        }
      : initialFormData
  )

  const { languageList } = useContext(ListContext);
    // const [selectedLanguage, setSelectedLanguage] = useState(formData.mobileSheetLocale?.[0]?.idLanguage || 1);
  const [localeData, setLocaleData] = useState(
    languageList.map((lang) => ({
        idLanguage: lang.idLanguage,
        items: [],
    }))
  );
  
  const handleResponse = useCallback(
    async (response, actionType) => {
      if (!response.error) {
        if (actionType === 'DELETE') {
          navigate('/owToolbox');
        } else {
          const updatedId = actionType === 'NEW' ? response.data.idMobileSheet : formData.idMobileSheet;
          try {
            const result = await serviceMobileSheets.getMobileSheetsDetails(updatedId, true);
            const { data, error } = result;
            if (!error) {
              // const updatedItems = data.mobileSheetItems.map((item, index) => ({
              //   ...item,
              //   idDrag: item.idDrag || `Item-${item.idMobileSheetItem || index}-${Date.now()}`
              // }));
              // setFormData({ ...data, mobileSheetItems: updatedItems });
              navigate(`/owToolboxDetail`, {
                state: { data, type: 'UPDATE' }
              });
            }
          } catch (error) {
            // Manejo de error (opcional)
          }
        }
      }
      setLoading(false)
    },
    [navigate, formData.idMobileSheet, setFormData]
  );
  
  
  const handleSubmit = useCallback(async (type) => {
    setLoading(true)
    let errors = []
  
    if (!formData.mobileSheetLocale || formData.mobileSheetLocale.some(locale => !locale.mobileSheetTitle)) {
      errors.push(t('OWTOOLS.WORKSHEET_TITLE_ERROR'))
    }
    
    const itemErrors = validateItems()
    if (itemErrors.length > 0) {
      errors = errors.concat(itemErrors)
    }
    
    if (!formData.mobileSheetName) {
      errors.push(t('OWTOOLS.WORKSHEET_NAME_ERROR'))
    }
  
    if (errors.length > 0) {
      snackActions.error(errors.join(', '))
      setLoading(false)
      return
    }
  
    switch (type) {
      case 'NEW':
        serviceMobileSheets.newMobileSheet(formData).then(response => handleResponse(response, 'NEW'))
        break
      case 'UPDATE':
        serviceMobileSheets.updateMobileSheet(formData).then(response => handleResponse(response, 'UPDATE'))
        break
      case 'DELETE':
        serviceMobileSheets.deleteMobileSheet(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        setLoading(false)
        break
    }
  }, [formData, handleResponse])
  


  useEffect(() => {
    serviceMobileSheets.getListMobileSheet().then(res => {
      setTypesList(res.data);
  
      // Mostrar el botón de "Añadir elemento"
      if (!MOBILESHEET_DATA?.idMobileSheet) {
        setShowAddElement(true)
        handleAddItem() // Agregar un elemento automáticamente si no hay idMobileSheet
      } else {
        setShowAddElement(true) // El botón se muestra haya idMobileSheet o no
      }
  
      setLoading(false) // Desactivar loading al final
    }).catch(() => setLoading(false)) // Desactivar loading en caso de error
  }, [])
  

  const validateItems = useCallback(() => {
    let errors = []
    let contentErrorAdded = false

    for (let item of formData.mobileSheetItems) {
      if (!item.mobileSheetItemLocale || item.mobileSheetItemLocale.length === 0) {
        if (!contentErrorAdded) {
          errors.push(t('OWTOOLS.CONTENT_ERROR'))
          contentErrorAdded = true
        }
      }
    }
    return errors
  }, [formData.mobileSheetItems, t])

  const onDragEnd = useCallback(e => {
    const { destination, source } = e
    if (!destination) return
    const newItems = reorder(
      formData.mobileSheetItems,
      source.index,
      destination.index
    ).map((e, i) => ({...e,
      mobileSheetItemOrder: (i + 1) }))
    setFormData(prevState => ({ ...prevState, mobileSheetItems: newItems }))
  }, [formData.mobileSheetItems, setFormData])

  const handleChangeLocaleTitle = useCallback((data, idLanguage, name) => {    
    let localeLanguage = formData?.mobileSheetLocale || []
    let index = localeLanguage.findIndex(
      locale => locale.idLanguage == idLanguage
    )
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage
      })
      index = localeLanguage.length - 1 
    }
    localeLanguage[index][name] = data
    setFormData({ ...formData, mobileSheetLocale: localeLanguage })
  }, [formData, setFormData])

  const handleChangeColor = useCallback((color, name) => {
    setFormData({ ...formData, [name]: color })
  }, [formData, setFormData])

  const handleAddItem = () => {
    const newItem = {
        idDrag: `Item-${formData?.mobileSheetItems.length}-${Date.now()}`,
        // idMobileSheet: formData?.idMobileSheet || null, // Asegura el ID del formulario actual
        idMobileSheetItemType: 10 || null, // Tipo por defecto, ajusta si es necesario
        mobileSheetItemTextColor: '#000000',
        mobileSheetItemBackColor: '#E2EDF3',
        mobileSheetItemAlign: 'center',
        mobileSheetItemIndent: false,
        mobileSheetItemBorder: false,
        mobileSheetItemLocale: [],
        // languageList.map((lang) => ({
        //     idLanguage: lang.idLanguage,
        //     mobileSheetItemContent: formData?.mobileSheetItems?.mobileSheetItemLocale?.find((locale) => locale.idLanguage === lang.idLanguage ).mobileSheetItemContent || 'XXX', // Contenido inicial vacío
        // })),
        mobileSheetItemOrder: formData.mobileSheetItems.length,
        isOpenWorksheet: false, // Estado inicial
        isCloseWorksheet: false, // Estado inicial
    };

    setFormData((prevState) => ({
        ...prevState,
        mobileSheetItems: [...prevState.mobileSheetItems, newItem],
    }));
  }

  const handleUpdateItem = useCallback((updatedItem, index, idDrag) => {    
    const updatedItems = formData.mobileSheetItems.map((item) => {
        if (item.idDrag === idDrag) {
          const languageExists = item.mobileSheetItemLocale.some(
            (locale) => locale.idLanguage == selectedLanguage
         );

          const updatedLocales = item.mobileSheetItemLocale.map((locale) =>
            locale.idLanguage == selectedLanguage
                ? { ...locale, mobileSheetItemContent: updatedItem } // Actualiza si existe
                : locale
          );

          //  Si el idioma no existe, lo agregamos al final
          
          if (!languageExists) {
              updatedLocales.push({
                  idLanguage: selectedLanguage,
                  mobileSheetItemContent: updatedItem,
              });
          }
                      
          return { ...item, mobileSheetItemLocale: updatedLocales };
        }
        return item;
    });


    setFormData({ ...formData, mobileSheetItems: updatedItems });
}, [formData, setFormData]);

  if (!typesList.length) return <Loading />

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_TOOLBOX')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />
      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          urlBack='/owToolbox'
        />
      </HeaderTitle>

      <Grid container style={{position: 'sticky', top: 0, zIndex: 100 }} mb={1}>
        <Card fullWidth style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '75%', padding: '0px 0px 0px 15px'}}>
        <Grid item lg={5.5} xs={12} style={{ marginBottom: '20px', backgroundColor: 'white' }} pt={1}>
          <Typography variant='caption'>
            {t('OWTOOLS.WORKSHEET_NAME')}
          </Typography>
          <TextField
            size='small'
            name='mobileSheetName'
            fullWidth
            value={formData.mobileSheetName}
            onChange={handleChange}
          />
        </Grid>
        </Card>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <Card fullHeight>
                <Typography
                  color='primary'
                  variant='subtitle1'
                  mb={2}
                  textAlign='center'
                  className={classes.cardTitle}
                >
                  {t('CYCLE_TYPES.TITLE_GLOBAL_CONFIG').toUpperCase()}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  display='flex'
                  alignItems='flex-end'
                >
                  <Grid item xs={6}>
                    <Typography variant="caption" component='div'>
                    {t("OWTOOLS.WORKSHEET_TITLE")}
                    </Typography>
                    <TextField
                      size="small"
                      name="mobileSheetTitle"
                      fullWidth
                      value={formData?.mobileSheetLocale?.find(locale => locale.idLanguage == selectedLanguage)?.mobileSheetTitle || ""}
                      onChange={(e) =>
                        handleChangeLocaleTitle(
                          e.target.value,
                          selectedLanguage,
                          "mobileSheetTitle"
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='caption' component='div'>
                      {t('OWTOOLS.WORKSHEET_UNLOCK_CODE')}
                    </Typography>
                    <TextField
                      size='small'
                      name='mobileSheetCode'
                      fullWidth
                      value={formData?.mobileSheetCode || ''}
                      onChange={handleChange}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant='caption' component='div'>
                      {t('LABEL.BACKGROUND')}
                    </Typography>
                    <ColorPicker
                      bgColor={formData.mobileSheetColor}
                      onChangeComplete={e => {
                        handleChangeColor(e.hex, 'mobileSheetColor')
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CheckBox
                      label={t('LABEL.SHOW_LOGO')}
                      name='mobileSheetLogo'
                      value={formData?.mobileSheetLogo || ''}
                      checked={formData?.mobileSheetLogo}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <DraggableElements
                  selectedLanguage={selectedLanguage}
                  onDragEnd={onDragEnd}
                  formData={formData}
                  setFormData={setFormData}
                  typesList={typesList}
                  onUpdateItem={handleUpdateItem}
                />
                {showAddElement && (
                  <Chip
                    size='small'
                    onClick={handleAddItem}
                    label={t('LABEL.ADD_ELEMENT')}
                    color='primary'
                    sx={{ padding: 0 }}
                    icon={<AddCircleIcon />}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3} xs={12}>
          <Box
            p={2}
            style={{
              position: 'sticky',
              top: 15
            }}
          >
            <ReactDevicePreview device='nexus5' scale='1'>
              <ContentMobileForOWToolbox formData={formData} selectedLanguage={selectedLanguage} />
            </ReactDevicePreview>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default OwToolboxDetail
